import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../templates/Layout';

const Donate = ({ data: { donate } }) => {
  return (
    <Layout siteData={donate.datoCmsSetting}>
      {/* <Img fluid={donate.photo.fluid} className="donate__banner tablet" /> */}
      <article className="sheet">
        <h1 className="sheet__title">{donate.title}</h1>
        <Img
          fluid={donate.photo.fluid}
          className="donate__banner desktop"
          objectFit="fill"
          objectPosition="100% 100%"
        />
        {/* <Img fluid={donate.photo.fluid} className="donate__banner desktop" /> */}
        <div
          className="sheet__body"
          dangerouslySetInnerHTML={{
            __html: donate.descriptionNode.childMarkdownRemark.html,
          }}
        />
      </article>
    </Layout>
  );
};

export const query = graphql`
  query DonateQuery($locale: String!) {
    donate: datoCmsDonatePage(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      photo {
        fluid(maxWidth: 1200, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
`;

export default Donate;
